$L.prototype.extend({
	cruxTextareaBindResize : function(op){
		var self = this[0];
		if(!self.getData("ltPropDisabled") && !self.getData("ltPropReadonly")){
			self.setMethods({
				onResizeEnd : function(){
					self.cxResized = true;
				}
			})
			Lyte.Component.addLyteEventListener(self, "click", function(){//No I18n
				increaseHeight();
			});
			Lyte.Component.addLyteEventListener(self, "keyup", function(ev){//No I18n
				if((ev && ev.key != "Tab") || !ev){
		    		increaseHeight();
		    	}
			});
		}
		function increaseHeight(){
			if(self.cxResized){
				return false
			}
			var height = 71;
			if(op && op.height){
				height = op.height.split("px")[0];//no i18n
			}
			self.querySelector("textarea").style.height = height+"px";//No I18n
			if(self.getData("ltPropDirection") == "vertical" && self.querySelector(".lyteLabel")){
				height+=self.querySelector(".lyteLabel").offsetHeight;//No I18n
			}
	    	self.style.height = (height+2)+"px";	//No I18n
		}
		if(self.getData("ltPropValue")){
			increaseHeight();
		}
		self.ltProp("textAreaResize", {vertical : true, horizontal : false})//No I18n
	}
 })
